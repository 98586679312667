<template>
    <zw-sidebar @shown="shown" :title="$t('multichannel.title.change_attribute')">
        <div class="px-3 py-2">
            <validation-observer tag="div" ref="observer">
                <b-overlay :show="loading" no-wrap></b-overlay>
                <div v-if="!loading">
                    <b-row>
                        <b-col cols="12">
                            <b-alert variant="info" show v-if="form.source == 'selected'">
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('article.text.selected_count', {'count': payload.selectedData.ids.length}) }}
                            </b-alert>
                            <b-alert variant="info" show v-else>
                                <font-awesome-icon icon="info-circle" class="mr-2"></font-awesome-icon>
                                {{ $t('export.source.' + form.source) }}
                            </b-alert>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6">
                            <zw-input-group v-model="form.new_value"
                                            name="new_value"
                                            :label-prefix="labelPrefix"
                            ></zw-input-group>
                        </b-col>

                        <b-col cols="6">
                            <zw-select-group v-model="form.attribute"
                                             :options="getAttributes() | optionsVV"
                                             name="attribute"
                                             :label-prefix="labelPrefix"
                                             validate="required"
                            ></zw-select-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col sm="12" class="text-xs-center">
                            <b-button block @click="onSubmit()" variant="primary">
                                {{ $t('common.button.save') }}
                            </b-button>
                        </b-col>
                    </b-row>
                </div>
            </validation-observer>
        </div>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'ChangeStoreAttributeModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {
                new_value: '',
                attribute: null,
                source: 'selected',
                ids: [],
                id: 1, // for update message
            },
            form: {},
            labelPrefix: 'multichannel.label.change_attribute.',
        }
    },
    methods: {
        ...mapGetters('Multichannel', ['getAttributes']),
        shown() {
            const articleAttributes = this.$store.dispatch('Multichannel/fetchAttributes')

            Promise.all([articleAttributes])
                .then(() => {
                    this.form = JSON.parse(JSON.stringify(this.defaultForm))
                    this.form.ids = this.payload.selectedData.ids
                    this.form.source = this.payload.selectedData.selectedSource
                })
                .finally(() => {
                    this.loading = false
                })
        },
        onSubmit() {
            this.$refs['observer'].validate().then(valid => {
                if (valid) {
                    this.loading = true
                    let filter = this.payload.selectedData.filter;
                    filter['api_source_id'] = this.payload.sourceId
                    filter['api_store_id'] = this.payload.storeId

                    let sendData = {
                        ...this.payload.selectedData,
                        filter: filter,
                        form: this.form,
                    }

                    this.$store.dispatch('Multichannel/updateAttributes', sendData)
                        .then((response) => {
                            this.commonAfterSave(response)
                        })
                        .catch(errors => {
                            this.$refs['observer'].setErrors(errors)
                            this.showValidationError()
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.showValidationError()
                }
            })
        },
    }
}
</script>